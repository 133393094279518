import React from 'react';

function Home() {
  return (
    <div className="App">
		<div className="body">
      <p>Hopefully this will be a better version of my previous website :D</p>
		</div>
    </div>
  );
}

export default Home;
