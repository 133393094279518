import React from 'react'


function About() {
	return (<div>
		<h1> hi </h1>
	</div>
	)
}

export default About